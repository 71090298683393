import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Search for content`}</h4>
    <p>{`Wherever you are in the app, you can always start searching for content by
typing in text in the search box at the top.`}</p>
    <br />
    <p>{`As soon as you typed in the first few letters you will get suggestions.`}</p>
    <Image src="features/search.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <p>{`The first search results are locations, indicated by a pin. Click on the
location to jump to this place on the map.`}</p>
    <br />
    <p>{`Other search results are trails, users, hashtags, custom maps. Click on
what you like to see.`}</p>
    <br />
    <p>{`A field beneath the search box shows three numbers. The first one is the
number of found activities in the current map section. The second is the
number of found activities globally. The third is the total amount of
activities of the current selected activity type.`}</p>
    <br />
    <br />
    <h4>{`Offline`}</h4>
    <p>{`In theory data you loaded once is cached and available offline. The trails,
pictures, description, the base map (only the map sections and zoom levels
you loaded recently).`}</p>
    <br />
    <p>{`BUT! Don't rely on this for offline navigation in the mountains! In this case
download the GPX file and use it on your GPS device.`}</p>
    <br />
    <p>{`Tip: it works the best if you load what you need, then turn of your mobile
data to prevent your phone from trying to load new data.`}</p>
    <br />
    <br />
    <Link className="float-left" to="/features/downloadtrack" mdxType="Link">
  ← Download GPX track
    </Link>
    <Link className="float-right" to="/features/filter" mdxType="Link">
  Filter →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      